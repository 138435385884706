<template>
  <div class="AISBench" id="homebox">
      <div id="pagebox" class="contail">
        <nav class="bottom-bar" ref="headerNav" :class="headerFixed?'bottom-bar-fixed':'bottom-nav'">
          <div class="bottom-bar-inner">
            <div class="spanner"></div>
            <div class="navs" v-for="(item, index) in objData" :key="index" >
              <a @click="tabsHref(item.id)" :class="{'active':activeBtn==item.id?true:false}">{{item.title}}</a>
            </div>
          </div>
        </nav>

        <div class="home-text page-pad" v-for="(item, index) in objData" :key="index" :id="item.id">
          <!--     标头     -->
          <h1 class="home-text__title text-lg col-pad" :class="{'link-styl':false}">
            <span> {{item.title}}</span>
         
          </h1>

       

<!--          <div class="title-split"></div>-->
          <div class="markdown-area col-pad">
            <!--      描述      -->
            <div v-if="item.p && item.p.length > 0">
              <p v-for="(pl,i) in item.p" :key="i">{{pl.pdesc}}</p>
            </div>
            <!--     模块       -->
            <div class="brand-pillars" v-if="item.model && item.model.length != 0">
              <div class="brand-pillars-box" v-for="(it, index1) in item.model" :key="index1">
                <h1>{{it.title}}</h1>
                <img :src="require('@/assets/'+it.modelImgUrl)" alt="Line chart illustration" width="400" height="200">
                <p>{{it.desc}}</p>
              </div>
            </div>
 
            <!--     相关标准表格       -->
            <div v-if="item.tableArr &&  item.tableArr.tableList && item.tableArr.tableList.length>0 " style="margin-top: 2rem;">
              <el-table
                  :data="item.tableArr.tableList"
                  style="width: 100%">
                <el-table-column
                    type="index"
                    label="序号"
                    width="50">
                </el-table-column>
                <el-table-column
                    label="标准名称"
                   >
                  <template slot-scope="scope">
                    <a :href="scope.row.url" target="_blank">
                      <span style="color: #2088ef;">{{scope.row.title}} </span>
                    </a>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="bzbh"
                    width="260"
                    label="标准编号">
                </el-table-column>
                <!-- <el-table-column
                    width="140"
                    prop="fbsj"
                    label="发布时间">
                </el-table-column> -->
              </el-table>
            </div>
            <!--    列表        -->
            <ul class="list-item" v-if="item.ulli&& item.ulli.length > 0">
              <li v-for="(pli,i) in item.ulli" :key="i"><span class="bgimg"></span>{{pli.li}}</li>
            </ul>
          </div>
          <!--  下载 -->
          <div class="markdown-area col-pad"  v-show="item.downloads && item.downloads.length > 0" v-for="(tool,i) in item.downloads" :key="i">
            <a  :href="tool.flieUrl" class="{link-styl:true}" target="_new">
              <span style="color: #2088ef;" >{{tool.name}} 下载</span>
            </a>
            <!-- <span  @click="downLoadUrl(tool.flieUrl)">{{tool.name}} 下载</span> -->
          </div>
            <!--     图片-->
          <div class="markdown-area" v-if="item.img" style="text-align: center;margin-top: 2rem;">
            <img :src="require('@/assets/'+item.img.imgUrl)" width="100%" />
          </div>
          <div class="markdown-area partener-pillars">
            <div class="partener-pillars-box" v-show="item.imgs && item.imgs.length > 0" v-for="(model,i) in item.imgs" :key="i" >
              <img :src="require('@/assets/'+model.imgUrl)" >
            </div>
          </div>
   

        </div>
      </div>
  </div>
</template>

<script>
import sjImg from '@/assets/sj.png';
import dataArr from "./data.json"
export default {
  name: 'HelloWorld1',
  props: {
    msg: String
  },
  data(){
    return {
      objData:{},
      activeBtn: 'jieshao',
      sjImg,
      headerFixed: 0
    }
  },
  mounted(){
    this.objData = dataArr.jsonData;
    this.$nextTick(() => {
      // 获取吸顶元素的dom
      // let header = this.$refs.headerNav;
      // // 吸顶元素到top的距离
      // this.offsetTop = 200;
      // // 元素自身的高度
      // this.offsetHeight = header.offsetHeight;
      // 监听滚动条
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  methods:{
    tabsHref(v){
      // console.log(" click ", v)
      this.activeBtn = v;
      /*找到锚点*/
      let anchorElement = document.getElementById(v);
      // console.log(" anchor ", anchorElement)
      /*如果对应id的锚点存在，就跳转到锚点*/
      if(anchorElement) {
        // console.log(" scroll ")
        anchorElement.scrollIntoView();
      }
    },
    handleScroll() {
      // 得到页面滚动的距离，兼容写法
      let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      // 判断页面滚动的距离是否大于吸顶元素的位置
      //吸附距离调整
      this.headerFixed = scrollTop > 180;
    },
    downLoadUrl(url){
      window.open(url,'_target');
    },
    getImg(url){
      console.log(url)
      return require('@/assets/'+url);
      // return '';
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#homebox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.contail {
  min-height: 43.75rem;
  padding: 0.625rem 0 1.875rem 0;
}
#pagebox {
  width: 75rem;
}

.bottom-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 72px;
  z-index: 500;
  padding: 39px 130px 0;
  box-sizing: border-box;
}
.bottom-bar-fixed{
  position: fixed!important;
  top: 0;
  left: 0;
  right: 0;
  height: 72px;
  z-index: 500;
  padding: 39px 130px 0;
  box-sizing: border-box;
  background-color: hsla(0,0%,100%,.5);
}
.bottom-nav{
  position: relative;
}
.bottom-bar:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 0;
  /*background-color: hsla(0,0%,100%,.5);*/
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  transition: background-color .2s ease,opacity .2s ease;
  opacity: 1;
}
.bottom-bar-inner {
  position: relative;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.title-split {
  height: 3px;
  width: 40px;
  background: #000;
  margin: 0 auto 1rem;
}
.brand {
  font-size: 12px;
  font-family: "Source Code Pro",Menlo,Monaco,Consolas,"Courier New",monospace;
  color: #fff;
  display: inline-flex;
  align-items: center;
  z-index: 1;
  transition: opacity .2s ease,transform .2s ease;
}
img.brand {
  width: 140px;
  height: 15px;
}

.spanner {
  flex: 1 1;
}

.navs, .navs a {
  height: 100%;
  display: inline-flex;
  align-items: flex-start;
}

.navs a {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  padding: 0 10px;
  font-family: "GothamPro";
  font-weight: 400;
  font-size: 14px;
  opacity: .4;
  transition: color .2s ease,opacity .2s ease,padding .2s ease;
  letter-spacing: 1px;
  color: #111;
  justify-content: center;
  position: relative;
}

.navs a.active {
  padding: 0 20px;
  opacity: 1;
}

.navs a.active:after {
  opacity: 1;
}
.navs a:after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  right: 0;
  bottom: 0;
  height: 3px;
  width: 38px;
  transform: translateX(-50%);
  border-radius: 1.5px;
  background-color: #000;
  opacity: 0;
  transition: opacity .2s ease,background-color .2s ease;
}

.home-text__title {
  margin-bottom: 4rem;
  margin-top: 0rem;
  padding-top: 6rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* max-width: 15em; */
  font-size: 3.2rem;
  width: 43rem;
  font-family: inherit;
  font-weight: inherit;
}

.markdown-area{
  padding: 0 9rem;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
}
.markdown-area p{
  text-align: left;
  display: inline-block;
  text-indent: 2em;
}
.markdown-area p+p{
  margin-top: 0;
}

markdown-area-img{
  text-align: center;
  padding: 0 10rem;
}

.markdown-area-img img{
  text-align: center;
}


.partner-pillars {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  overflow-x: auto;
  max-width: 60rem;
  margin: 0 auto 4rem;
}

.partner-pillars-box {
  flex: 0 0 66.66%;
  margin: 0.5rem;
  padding: 1rem 0;
  width: 33%;

}

.partener-pillars{
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.partener-pillars-box{
  margin-right: 20px;
}

.partener-pillars-box img{
  height: 60px;
  width: 100%;
}

.brand-pillars {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  overflow-x: auto;
  max-width: 60rem;
  margin: 2rem auto 4rem;
}

.brand-pillars-box {
  flex: 0 0 66.66%;
  margin: 0.5rem;
  padding: 1rem 0;
  width: 33%;
}
.brand-pillars-box h1{
  border-top: 1px solid #000;
  padding-top: 0.5rem;
  margin: 0 1rem 1rem 1rem;
  font-size: 1.2rem;
  line-height: 1.2;
}
.brand-pillars-box {
  flex: 1 1 0;
}
.brand-pillars-box img{
  width: 100%;
  height: auto;
}
.brand-pillars-box p{
  padding-top: 0.5rem;
  margin: 0 1rem 1rem 1rem;
  font-size: 1rem;
  line-height: 1.2;
}
.brand-pillars>div:first-child {
  background-color: #ff6d6a;
}
.brand-pillars>div:nth-child(2) {
  background-color: #2088ef;
}
.brand-pillars>div:nth-child(3) {
  background-color: #ccebd4;
}

.t-content{
  text-align: center;
}

.bgimg{
  width: 20px;
  height: 16px;
  display: inline-block;
  /*background: url("../../assets/sj.png") no-repeat;*/
}

ul, ul li{
  list-style: none;
  text-align: left;
}

ul li{
  height: 3rem;
}
.link-styl{
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: 3rem;
  color: #2088ef;
  /*text-decoration: underline;*/
  cursor: pointer;
  margin-bottom: 2rem;
  /*margin-top: 5rem;*/
  /*border-bottom: 1px solid #2088ef;*/
}
.link-styl:hover{
  color: #0b15d9;
}

</style>
<style>
.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf,.el-table__header-wrapper,.el-table__body-wrapper {
  border-bottom: 1px solid #bbbcbf;
}
</style>